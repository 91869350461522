.btn {
  @apply rounded-lg p-4 flex justify-center items-center
  font-primary text-base leading-none font-medium tracking-[0.4px] uppercase cursor-pointer
  border;

  &.btn-no-padding {
    @apply p-0;
  }
  &.btn-no-border {
    @apply border-0;
  }
  &.btn-no-px {
    @apply px-0;
  }

  &.disabled,
  &[disabled] {
    @apply cursor-not-allowed;
  }
}

.btn-lg {
  @apply text-lg leading-6 py-4 px-6;
}

.btn-primary {
  @apply bg-primary border-primary hover:bg-primary-light hover:border-primary-light text-white;

  &[disabled] {
    @apply bg-gray text-gray-dark  border-none;
    @apply cursor-not-allowed;
  }
}

.btn-secondary {
  @apply bg-gray-light hover:bg-gray-50 border-gray-light hover:border-gray-50 text-black;

  &[disabled] {
    @apply bg-gray-neutre text-gray-dark  border-none;
    @apply cursor-not-allowed;
  }
}

.btn-tertiary {
  @apply bg-transparent text-black border-black hover:bg-black hover:text-white transition-colors;

  &[disabled] {
    @apply border-gray text-gray-dark hover:bg-transparent;
    @apply cursor-not-allowed;
  }
}

.btn-light-pink {
  @apply bg-pink-pastel-light border-none text-primary leading-6 font-medium	tracking-normal py-1 px-1.5 transition-colors;

  &[disabled] {
    @apply bg-gray-light text-gray;
    @apply cursor-not-allowed;
  }
}
