@import './components/index.scss';

body {
  @apply w-full h-full overflow-x-hidden;
  min-height: 100%;
}

div.app {
  @apply w-full min-h-full relative;

  .has-background {
    > * {
      position: relative;
    }
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0.5;
      background-image: url('./../assets/custom-icons/drawer.svg');
      @apply bg-repeat bg-gray-light;
    }
  }
}

.bg-image {
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url('./../assets/custom-icons/drawer.svg');
  @apply bg-repeat bg-gray-light;
}

.optimerix-logo {
  height: 145px;
  width: 165px;
}

.datepicker-header {
  @apply font-alternate;
}

.datepicker-main {
  @apply font-primary;
}

.datepicker-cell {
  &.selected {
    @apply bg-primary;
  }
  &.next,
  &.prev {
    @apply font-normal text-gray-dark;
  }
}

.today-btn {
  &:hover {
    background: #9b261d !important;
  }
  @apply bg-primary;
}
