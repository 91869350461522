.menu-container {
  @apply box-border bg-white p-4 pb-6 rounded-b-3xl md:rounded-b-none md:rounded-r-3xl shadow-campaignCardBoxShadow md:shadow-lg transition-width ease-in-out duration-300 w-full md:w-28 md:hover:w-60 md:min-h-[700px] md:h-screen md:flex flex-col fixed top-0 left-0 right-0 md:absolute z-10;

  .menu-item {
    @apply w-full text-center;

    a {
      @apply inline-block w-full md:w-16 md:hover:w-full p-4 rounded-2xl transition-background-color ease-in-out duration-300 whitespace-nowrap overflow-hidden text-left hover:bg-primary hover:text-white;

      &.active-link,
      &:hover {
        @apply bg-primary;

        svg-icon,
        span {
          @apply text-white;
        }
      }

      svg-icon,
      span {
        @apply transition-color ease-in-out duration-300 inline-block align-middle;
      }

      svg-icon {
        @apply text-gray-dark;
      }

      span {
        @apply md:opacity-0 transition-opacity ease-in-out duration-300;
      }
      .menu-label {
        @apply w-0 h-0 inline-block;
      }
    }
  }

  &:hover {
    .menu-item a {
      @apply w-full;

      span {
        @apply opacity-100;
      }
      svg-icon {
        @apply mr-1;
      }
    }
    .menu-label {
      width: 100% !important;
      height: inherit !important;
    }
  }
}
