.ngx-toastr {
  width: 100vw !important;
}

.toast-success {
  background-image: none !important;
  background-color: #fff7f3 !important;
  color: #737380 !important;
}

.toast-error {
  background-image: none !important;
  background-color: #900e04 !important;
}
.toast-warning {
  background-image: none !important;
  background-color: #ffae8b !important;
  color: #737380 !important;
}
.toast-info {
  background-image: none !important;
  background-color: #fffad9 !important;
  color: #737380 !important;
}
