/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Project theme */
@import './theme/theme';
@import 'ngx-toastr/toastr';
@import '@angular/cdk/overlay-prebuilt.css';

app-notification-modal {
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.remove-scrollbar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.remove-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
/*hid captcha-badge .grecaptcha-badge { visibility: hidden; } */
